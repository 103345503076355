<template>
  <div>
    <v-menu :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <span class="d-inline-flex align-center filter-title" :class="{ 'font-weight-bold': isFilterApplied }" v-on="on" v-bind="attrs">
          <v-icon left> date_range </v-icon>
          Last Used In
          <v-icon> expand_more </v-icon>
        </span>
      </template>
      <v-card>
        <v-card-text :class="{ 'black--text': isThemeLight }">
          <div class="mb-3">
            <v-icon :class="{ 'black--text': isThemeLight }" class="mr-1" left> date_range </v-icon>

            Last Used In
          </div>

          <!-- Show the date range selector here -->
          <date-range-selector
            :max="dayjs().format('YYYY-MM-DD')"
            :min="dayjs().subtract(365, 'day').format('YYYY-MM-DD')"
            :value="dateRangeValue"
            :default-date-range="3"
            @input="handleDateRangeValue"
            dense
          />

          <div class="d-flex justify-space-between align-center mt-3 pr-1">
            <v-checkbox v-model="form.inverted" hide-details label="Invert Selection" class="mt-0" @change="submitForm"></v-checkbox>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// Import helper functions
import { required } from "vuelidate/lib/validators"

// Import children components
const DateRangeSelector = () => import(/* webpackChunkName: "date-range-selector" */ "@/blocks/common/form/DateRangeSelector.vue")

// The filter key name
const filterName = "lastActiveAt"

// Export the SFC
export default {
  // Name of the component
  name: "FilterLastActive",

  // Register components
  components: {
    DateRangeSelector
  },

  // Define local data variables
  data: () => ({
    dateRangeValue: null,

    form: {
      inverted: false,
      from: "",
      to: ""
    }
  }),

  // Define computable readonly variables
  computed: {
    /**
     * Use the inputs to generate some text value
     *
     * @returns {String}
     */
    textValue() {
      return `Last used ${this.form.inverted ? "not" : ""} within: ${dayjs(this.form.from).format("ll")} - ${dayjs(this.form.to).format("ll")}`
    },

    /**
     * Whether or not this filter has been applied
     *
     * @returns {Boolean}
     */
    isFilterApplied() {
      return Boolean(this.$store.getters["admin/users/findFilterByType"](filterName))
    }
  },

  // Define validations for the local form
  validations: {
    form: {
      from: {
        required
      },
      to: {
        required
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * When called, add the filter to the Vuex store
     *
     * @returns {void}
     */
    async submitForm() {
      await this.$v.$touch()

      if (this.$v.$anyError) return

      this.$store.dispatch("admin/users/replaceFilter", {
        sort: 1,
        type: filterName,
        data: {
          color: "green lighten-4",
          icon: "date_range",
          iconColor: "green",
          text: this.textValue,
          inputs: {
            ...this.form
          }
        }
      })
    },

    /**
     * Copy the value from child to current state
     *
     * @param {null|Array} value
     */
    handleDateRangeValue(value) {
      // Copy the value to local value
      this.dateRangeValue = value

      // Check if it's an array
      if (value !== null) {
        // Also update the filter values
        this.form.from = value[0] || null
        this.form.to = value[1] || null

        this.submitForm()
      }
    }
  }
}
</script>
